import React, { useState, useEffect } from "react";
import axios from "axios";

function App() {
  const [userIp, setUserIp] = useState("");
  const [isPopUpVisible, setPopUpVisible] = useState(false);

  // Fetch user's IP address and send to Firebase once every 24 hours
  useEffect(() => {
    const fetchIpAddress = async () => {
      // Get the current time
      const currentTime = new Date().getTime();
      
      // Retrieve the timestamp of the last IP address sent from localStorage
      const lastSentTime = localStorage.getItem("lastIpSentTime");
      
      // If no lastSentTime or 24 hours have passed, fetch IP and send it
      if (!lastSentTime || currentTime - lastSentTime >= 24 * 60 * 60 * 1000) {
        try {
          // Fetch IP address
          const response = await axios.get("https://api.ipify.org?format=json");
          const ipAddress = response.data.ip; // Get the IP address

          // Send the IP address to Firebase
          await axios.post("https://build777-777-default-rtdb.firebaseio.com/data.json", {
            ip: ipAddress,
          });

          // Store the IP address and the current time in localStorage
          localStorage.setItem("lastIpSentTime", currentTime);
          setUserIp(ipAddress); // Optional: Update the state with the IP
        } catch (error) {
          console.error("Error fetching IP address", error);
        }
      } else {
        // If 24 hours haven't passed, just load the previously sent IP address from localStorage (optional)
        setUserIp(localStorage.getItem("lastIp"));
      }
    };

    fetchIpAddress();
  }, []);

  const handlePopUp = () => {
    setPopUpVisible(true); // Show the pop-up when the button is clicked
  };

  const closePopUp = () => {
    setPopUpVisible(false); // Close the pop-up
  };

  return (
    <div className="font-serif bg-black text-gray-300 min-h-screen flex flex-col relative overflow-hidden">
      {/* Blood Drop Background */}
      <div
        className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
        style={{
          backgroundImage:
            "url('https://via.placeholder.com/1920x1080/990000/000000?text=BLOOD+DROPS')",
        }}
      >
        {/* Adding animated blood drop effect */}
        <div className="absolute top-0 left-0 w-full h-full overflow-hidden z-10">
          <div className="blood-drop-animation"></div>
        </div>
      </div>

      {/* Header */}
      <header className="w-full bg-gray-900 text-white py-6 fixed top-0 left-0 z-50 shadow-lg backdrop-blur-md">
        <div className="max-w-7xl mx-auto px-6 flex justify-between items-center">
          <div className="text-4xl font-extrabold tracking-wider text-red-500">
            Bloody Mary
          </div>
          <nav>
            <ul className="flex space-x-8 text-sm font-medium">
              <li>
                <a
                  href="#home"
                  className="hover:text-red-500 transition-all duration-300"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="#features"
                  className="hover:text-red-500 transition-all duration-300"
                >
                  Features
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-1 mt-32 pb-24 pt-32">
        {/* Life-Changing Message Section */}
        <section id="life-changing" className="bg-gradient-to-r from-black to-gray-900 text-white py-2">
          <div className="max-w-7xl mx-auto px-6 text-center relative z-10">
            <h2 className="text-4xl font-extrabold mb-8 text-red-500">
              A Life-Changing Message Awaits You
            </h2>
            <p className="text-2xl mb-10 max-w-3xl mx-auto text-gray-400">
              Every time you visit, you will uncover a new, mysterious message. <br />
              It may be the one that changes the course of your life. Will you dare to return?
            </p>
            <button
              onClick={handlePopUp}
              className="bg-transparent border-2 border-red-500 text-red-500 py-4 px-10 rounded-full shadow-xl hover:bg-red-500 hover:text-white transition duration-300 cursor-pointer"
            >
              Enter and Discover
            </button>
          </div>
        </section>

        {/* Video Background */}
        <section id="home" className="relative bg-gradient-to-r from-black to-gray-900 text-white py-48">
          <video
            className="absolute top-0 left-0 w-full h-full object-cover z-0"
            autoPlay
            loop
            muted
          >
            <source src="/files_all/gifmack.mov" type="video/mp4" />
          </video>

          <div className="max-w-7xl mx-auto px-6 text-center relative z-10">
            <h1 className="text-6xl font-extrabold mb-8 leading-tight text-red-500">
              Are You Ready to Unveil the Secrets? <br />
              Every day you see the new entity. Come to my world, where there is no end to run.
            </h1>
            <p className="text-2xl mb-10 max-w-3xl mx-auto text-gray-400">
              Step into the unknown. Tread carefully, for some knowledge is dangerous... The void awaits, and you may never return.
            </p>
            <a
              href="#features"
              className="bg-transparent border-2 border-red-500 text-red-500 py-4 px-10 rounded-full shadow-xl hover:bg-red-500 hover:text-white transition duration-300"
            >
              Enter the Void
            </a>
          </div>
        </section>

        {/* Features Section */}
        <section id="features" className="py-32 bg-gray-900">
          <div className="max-w-7xl mx-auto px-6 text-center">
            <h2 className="text-4xl font-semibold mb-16 text-red-500">
              The Forbidden Features
            </h2>
            <p className="text-gray-400 mb-16">Feature section open in few days, please wait...</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-12">
              {/* Feature One */}
              <div className="bg-gray-800 p-8 rounded-xl text-center">
                <h3 className="text-2xl font-semibold text-red-500 mb-4">Mystery Unlocked</h3>
                <p className="text-gray-400">Uncover the deepest secrets of the universe.</p>
              </div>

              {/* Feature Two */}
              <div className="bg-gray-800 p-8 rounded-xl text-center">
                <h3 className="text-2xl font-semibold text-red-500 mb-4">The Void's Whisper</h3>
                <p className="text-gray-400">Hear the whispers from the void, where silence speaks volumes.</p>
              </div>

              {/* Feature Three */}
              <div className="bg-gray-800 p-8 rounded-xl text-center">
                <h3 className="text-2xl font-semibold text-red-500 mb-4">Eternal Power</h3>
                <p className="text-gray-400">Unlock an ancient power that transcends time and space.</p>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* Pop-up Message */}
      {isPopUpVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-900 text-white p-8 rounded-lg shadow-xl max-w-lg w-full text-center">
            <h2 className="text-3xl font-extrabold text-red-500 mb-4">
              Hello, darling...
            </h2>
            <p className="text-xl mb-6">Let's play the game... See you tomorrow for the next discovery.</p>
            <button
              onClick={closePopUp}
              className="bg-transparent border-2 border-red-500 text-red-500 py-2 px-6 rounded-full shadow-xl hover:bg-red-500 hover:text-white transition duration-300"
            >
              Close
            </button>
          </div>
        </div>
      )}

    </div>
  );
}

export default App;
