import React, { useState } from 'react';

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  // Toggle the mobile menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="bg-blue-500 text-white">
      <div className="container mx-auto flex justify-between items-center p-4">
        {/* Logo */}
        <div className="flex items-center space-x-3">
     
          <span className="text-xl font-semibold">Altai Krai</span>
        </div>

        {/* Desktop Menu */}
        <nav className="hidden md:flex space-x-8">
          <a href="#home" className="hover:text-yellow-300 transition-colors duration-300">Home</a>
          <a href="#services" className="hover:text-yellow-300 transition-colors duration-300">Services</a>
          <a href="#about" className="hover:text-yellow-300 transition-colors duration-300">About</a>
          <a href="#contact" className="hover:text-yellow-300 transition-colors duration-300">Contact</a>
        </nav>

        {/* Hamburger Menu Button (Visible on Mobile) */}
        <button
          className="md:hidden flex items-center space-x-2"
          onClick={toggleMenu}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>

      {/* Mobile Menu */}
      <div
        className={`${
          menuOpen ? 'block' : 'hidden'
        } md:hidden bg-blue-600 text-white space-y-4 px-4 py-4`}
      >
        <a href="#home" className="block py-2 hover:text-yellow-300">Home</a>
        <a href="#services" className="block py-2 hover:text-yellow-300">Services</a>
        <a href="#about" className="block py-2 hover:text-yellow-300">About</a>
        <a href="#contact" className="block py-2 hover:text-yellow-300">Contact</a>
      </div>
    </header>
  );
}

export default Navbar;
