import React from 'react';

function AltaiBanner() {
  return (
    <div className="relative font-sans before:absolute before:w-full before:h-full before:inset-0 before:bg-black before:opacity-50 before:z-10">
      <img
        src="https://russiatrek.org/images/photo/altai-krai-nature-view.jpg" // Replace with an actual high-quality Altai image URL
        alt="Altai Krai Landscape"
        className="absolute inset-0 w-full h-full object-cover"
      />

      <div className="min-h-[400px] relative z-50 h-full max-w-6xl mx-auto flex flex-col justify-center items-center text-center text-white p-6">
        <h2 className="sm:text-5xl text-3xl font-extrabold mb-6 tracking-wide">
          Discover the Wonders of Altai Krai
        </h2>
        <p className="sm:text-lg text-base text-center text-gray-200 max-w-2xl">
          Immerse yourself in the breathtaking landscapes, rich culture, and
          unique traditions of one of Russia's most stunning regions.
        </p>

        <button
          type="button"
          className="mt-8 bg-transparent text-white text-lg py-3 px-8 border border-white rounded-lg hover:bg-white hover:text-black transition duration-300">
          Start Your Journey
        </button>
      </div>
    </div>
  );
}

export default AltaiBanner;
