import React from 'react';

function AltaiBlog() {
  return (
    <div className="bg-white p-4 font-sans">
      <div className="max-w-5xl max-lg:max-w-3xl max-md:max-w-md mx-auto">
        <h2 className="text-3xl font-extrabold text-gray-800 text-center">LATEST BLOGS ABOUT ALTAI KRAI</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-12">
          {/* Blog 1 */}
          <div className="bg-purple-100 cursor-pointer rounded-md overflow-hidden group">
            <div className="relative overflow-hidden">
              <img
                src="https://peakvisor.com/img/news/Altai-mountain-Akkem-Valley.jpg"
                alt="Altai Mountains"
                className="w-full h-60 object-cover group-hover:scale-125 transition-all duration-300"
              />
              <div className="px-4 py-2.5 text-white text-sm tracking-wider bg-green-500 absolute bottom-0 right-0">
                November 20, 2023
              </div>
            </div>
            <div className="p-6">
              <h3 className="text-xl font-bold text-gray-800">The Enchanting Altai Mountains</h3>
              <p className="text-gray-600 text-sm mt-2">
                Discover the majestic landscapes of the Altai Mountains, a UNESCO World Heritage Site.
              </p>
              <button
                type="button"
                className="px-4 py-2 mt-4 rounded-md text-white text-sm tracking-wider bg-green-500 hover:bg-green-600"
              >
                Read More
              </button>
            </div>
          </div>
          {/* Blog 2 */}
          <div className="bg-purple-100 cursor-pointer rounded-md overflow-hidden group">
            <div className="relative overflow-hidden">
              <img
                src="https://epicureandculture.com/wp-content/uploads/2023/07/Altai-Mountains-Mongolia-featured.jpg"
                alt="Altai Culture"
                className="w-full h-60 object-cover group-hover:scale-125 transition-all duration-300"
              />
              <div className="px-4 py-2.5 text-white text-sm tracking-wider bg-blue-500 absolute bottom-0 right-0">
                October 15, 2023
              </div>
            </div>
            <div className="p-6">
              <h3 className="text-xl font-bold text-gray-800">Exploring Altai's Rich Culture</h3>
              <p className="text-gray-600 text-sm mt-2">
                Learn about the vibrant traditions and heritage of the Altai people.
              </p>
              <button
                type="button"
                className="px-4 py-2 mt-4 rounded-md text-white text-sm tracking-wider bg-blue-500 hover:bg-blue-600"
              >
                Read More
              </button>
            </div>
          </div>
          {/* Blog 3 */}
          <div className="bg-purple-100 cursor-pointer rounded-md overflow-hidden group">
            <div className="relative overflow-hidden">
              <img
                src="https://i.pinimg.com/originals/4d/a7/97/4da7972bea6da650fa15c250914e1c8a.jpg"
                alt="Altai Wildlife"
                className="w-full h-60 object-cover group-hover:scale-125 transition-all duration-300"
              />
              <div className="px-4 py-2.5 text-white text-sm tracking-wider bg-yellow-500 absolute bottom-0 right-0">
                September 10, 2023
              </div>
            </div>
            <div className="p-6">
              <h3 className="text-xl font-bold text-gray-800">Wildlife Adventures in Altai</h3>
              <p className="text-gray-600 text-sm mt-2">
                A closer look at the diverse flora and fauna of Altai Krai.
              </p>
              <button
                type="button"
                className="px-4 py-2 mt-4 rounded-md text-white text-sm tracking-wider bg-yellow-500 hover:bg-yellow-600"
              >
                Read More
              </button>
            </div>
          </div>
          {/* Blog 4 */}
          <div className="bg-purple-100 cursor-pointer rounded-md overflow-hidden group">
            <div className="relative overflow-hidden">
              <img
                src="https://th.bing.com/th/id/OIP.6oKtpulWe3oJ3O9imeYSiAHaEI?rs=1&pid=ImgDetMain"
                alt="Altai Rivers"
                className="w-full h-60 object-cover group-hover:scale-125 transition-all duration-300"
              />
              <div className="px-4 py-2.5 text-white text-sm tracking-wider bg-teal-500 absolute bottom-0 right-0">
                August 20, 2023
              </div>
            </div>
            <div className="p-6">
              <h3 className="text-xl font-bold text-gray-800">The Serenity of Altai Rivers</h3>
              <p className="text-gray-600 text-sm mt-2">
                Explore the serene rivers of Altai, perfect for rafting and fishing.
              </p>
              <button
                type="button"
                className="px-4 py-2 mt-4 rounded-md text-white text-sm tracking-wider bg-teal-500 hover:bg-teal-600"
              >
                Read More
              </button>
            </div>
          </div>
          {/* Blog 5 */}
          <div className="bg-purple-100 cursor-pointer rounded-md overflow-hidden group">
            <div className="relative overflow-hidden">
              <img
                src="https://venturephotography.com.au/wp-content/uploads/2019/11/DSF0096-2.jpg"
                alt="Altai Festival"
                className="w-full h-60 object-cover group-hover:scale-125 transition-all duration-300"
              />
              <div className="px-4 py-2.5 text-white text-sm tracking-wider bg-red-500 absolute bottom-0 right-0">
                July 30, 2023
              </div>
            </div>
            <div className="p-6">
              <h3 className="text-xl font-bold text-gray-800">Festivals of Altai</h3>
              <p className="text-gray-600 text-sm mt-2">
                Experience the lively festivals and celebrations of Altai Krai.
              </p>
              <button
                type="button"
                className="px-4 py-2 mt-4 rounded-md text-white text-sm tracking-wider bg-red-500 hover:bg-red-600"
              >
                Read More
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AltaiBlog;
