import React from 'react'

function ShoppingMart() {
  return (
    <div className="min-h-screen flex bg-gray-100">

      {/* Sidebar */}
      <div className="w-64 bg-blue-900 text-white p-6 flex flex-col">
        <h1 className="text-2xl font-bold mb-6 text-yellow-400">Shopping Mart</h1>
        <nav className="flex flex-col space-y-4">
          <a href="#" className="text-gray-300 hover:text-white transition duration-200">Home</a>
          <a href="#" className="text-gray-300 hover:text-white transition duration-200">Electronics</a>
          <a href="#" className="text-gray-300 hover:text-white transition duration-200">Clothing</a>
          <a href="#" className="text-gray-300 hover:text-white transition duration-200">Groceries</a>
          <a href="#" className="text-gray-300 hover:text-white transition duration-200">Sale</a>
        </nav>
        <div className="mt-auto text-gray-300">
          <a href="#" className="flex items-center hover:text-white transition duration-200">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5l-1.405-4.605A2 2 0 0018.236 14H6.763a2 2 0 00-1.36.395L4 20h5" />
            </svg>
            Cart (3)
          </a>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 bg-gray-50 p-8">
        {/* Top Navigation Bar */}
        <div className="flex justify-between items-center mb-8">
          <div className="text-3xl font-bold text-gray-700">Welcome to Shopping Mart</div>
          <div className="flex items-center space-x-4">
            <div className="text-gray-600">Welcome, User</div>
            <div className="h-8 w-8 bg-red-500 rounded-full"></div> {/* Placeholder for profile image */}
          </div>
        </div>

        {/* Promotional Banner */}
        <div className="bg-yellow-400 text-gray-800 p-4 rounded-lg mb-8 flex justify-between items-center">
          <div className="text-lg font-semibold">Limited Time Offer! 50% OFF on all Electronics.</div>
          <a href="#" className="bg-blue-700 text-white py-2 px-4 rounded-lg">Shop Now</a>
        </div>

        {/* Product Stats */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
          {/* Card 1 - Total Sales */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">Total Sales</h3>
            <p className="text-4xl font-bold text-green-500">$245,000</p>
          </div>

          {/* Card 2 - Products Sold */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">Products Sold</h3>
            <p className="text-4xl font-bold text-green-500">12,000</p>
          </div>

          {/* Card 3 - Customers */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">Customers</h3>
            <p className="text-4xl font-bold text-green-500">2,400</p>
          </div>
        </div>

        {/* Featured Products */}
        <div className="mb-8">
          <h3 className="text-2xl font-semibold text-gray-700 mb-4">Featured Products</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Product 1 */}
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <img src="https://via.placeholder.com/150" alt="Product" className="w-full h-48 object-cover rounded-lg mb-4" />
              <h4 className="text-lg font-semibold text-gray-700">Smartphone</h4>
              <p className="text-gray-500 mb-4">Latest model with 5G support</p>
              <p className="text-xl font-bold text-red-500">$599.99</p>
              <button className="w-full bg-blue-600 text-white py-2 rounded-lg mt-4">Add to Cart</button>
            </div>

            {/* Product 2 */}
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <img src="https://via.placeholder.com/150" alt="Product" className="w-full h-48 object-cover rounded-lg mb-4" />
              <h4 className="text-lg font-semibold text-gray-700">Leather Jacket</h4>
              <p className="text-gray-500 mb-4">Stylish leather jacket for winter</p>
              <p className="text-xl font-bold text-red-500">$249.99</p>
              <button className="w-full bg-blue-600 text-white py-2 rounded-lg mt-4">Add to Cart</button>
            </div>

            {/* Product 3 */}
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <img src="https://via.placeholder.com/150" alt="Product" className="w-full h-48 object-cover rounded-lg mb-4" />
              <h4 className="text-lg font-semibold text-gray-700">Coffee Maker</h4>
              <p className="text-gray-500 mb-4">Brew your favorite coffee with ease</p>
              <p className="text-xl font-bold text-red-500">$79.99</p>
              <button className="w-full bg-blue-600 text-white py-2 rounded-lg mt-4">Add to Cart</button>
            </div>
          </div>
        </div>

        {/* Recent Activities */}
        <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
          <h3 className="text-2xl font-semibold text-gray-700 mb-4">Recent Activities</h3>
          <ul className="space-y-4">
            <li className="flex justify-between">
              <div className="text-gray-700">Customer A purchased a Smartphone</div>
              <div className="text-gray-500">10 mins ago</div>
            </li>
            <li className="flex justify-between">
              <div className="text-gray-700">Customer B added a Jacket to the cart</div>
              <div className="text-gray-500">30 mins ago</div>
            </li>
            <li className="flex justify-between">
              <div className="text-gray-700">Customer C reviewed Coffee Maker</div>
              <div className="text-gray-500">1 hour ago</div>
            </li>
          </ul>
        </div>
      </div>

    </div>
  )
}

export default ShoppingMart;
