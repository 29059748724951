import React from 'react';

function AltaiHero() {
  return (
    <div className="font-sans bg-white py-12 px-4 bg-gradient-to-r from-green-200 via-blue-200 to-purple-300">
      <div className="max-w-7xl mx-auto text-center">
        {/* Heading */}
        <h2 className="text-gray-800 lg:text-6xl md:text-5xl text-3xl font-extrabold lg:leading-[64px] md:max-w-4xl mx-auto">
          Discover the Wonders of Altai Krai
        </h2>

        {/* Subheading and Button */}
        <p className="text-gray-600 text-lg leading-relaxed mt-6 max-w-2xl mx-auto">
          Explore the breathtaking landscapes, vibrant culture, and rich history of Altai Krai. Whether you're looking for adventure or relaxation, Altai has something for everyone.
        </p>
        <div className="mt-8 flex gap-4 justify-center items-center flex-wrap">
          <button
            type="button"
            className="bg-green-500 hover:bg-green-400 transition-all text-white font-bold text-sm rounded-full px-8 py-3"
          >
            Plan Your Visit
          </button>
          <a
            href="#learn-more"
            className="text-gray-800 text-sm font-bold underline whitespace-nowrap"
          >
            Learn More
          </a>
        </div>

        {/* Logos Section */}
        <div className="mt-12 grid grid-cols-2 md:grid-cols-4 gap-4 items-center">
          <img
            src="https://russiatrek.org/images/photo/altay-krai-wooden-church.jpg"
            className="w-28 mx-auto"
            alt="Altai Tourism Logo"
          />
          <img
            src="https://russiatrek.org/images/photo/altay-krai-nature-scenery.jpg"
            className="w-28 mx-auto"
            alt="Altai Flag"
          />
          <img
            src="https://i.pinimg.com/originals/8c/d3/7f/8cd37f76ecc5f00eb422356fc685914d.jpg"
            className="w-28 mx-auto"
            alt="Altai Coat of Arms"
          />
          <img
            src="https://thumbs.dreamstime.com/b/altai-region-russia-september-early-morning-mountains-krai-western-siberia-153136244.jpg"
            className="w-28 mx-auto"
            alt="Altai Mountains"
          />
        </div>

        {/* Image Section */}
        {/* <div className="mt-12">
          <img
            src="https://media.gettyimages.com/id/1241342420/photo/saint-petersburg-russia-a-performer-dressed-in-altai-region-traditional-wear-sings-and-dances.jpg?s=612x612&w=0&k=20&c=GD4uwrNDm83_WWiBLyMkjyXK7GHwPCm_bGO1QZCz-cE="
            alt="Altai Mountains Landscape"
            className="shrink-0 w-full h-auto rounded-md object-cover"
          />
        </div> */}
      </div>
    </div>
  );
}

export default AltaiHero;
