// src/App.js
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './com_shopmart/Home';
import HomePages from './com_game/HomePage';

import Home from './components/homepage';

const App = () => {
  return (
    <Router>
      <div>
        {/* Navigation bar */}
  

        {/* Routes for different pages */}
        <Routes>

        <Route path="/" element={<Home />} />
          <Route path="/shop-mart" element={<HomePage />} />
    
          

          <Route path="/com_game/Vinsta" element={<HomePages />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;


// {
//   "hosting": {
//     "public": "build",
//     "ignore": [
//       "firebase.json",
//       "**/.*",
//       "**/node_modules/**"
//     ]
//   }
// }
