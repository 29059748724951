import React from 'react'
import Blog from './Altai/Altai_section/Blog'
import Banner from './Altai/Altai_section/Banner'
import Hero from './Altai/Altai_section/Hero'
import Header from './Altai/Altai_section/Header'
import Footer from './Altai/Altai_section/Footer'

function homepage() {
  return (
    <>
    <div><Header /></div>
    <div><Hero /></div>
    <div><Blog /></div>
    <div><Banner /></div>
    <div><Footer /></div>
    </>
  )
}

export default homepage