import React from 'react';

function AltaiFooter() {
  return (
    <footer className="bg-[#f2f0eb] py-12 px-10 font-sans tracking-wide">
      <div className="lg:max-w-[50%] mx-auto text-center">
        <h3 className="text-3xl font-bold text-gray-800">Stay Connected</h3>
        <p className="text-sm mt-6 text-gray-600">
          Subscribe to receive updates on Altai Krai's hidden gems, cultural events, and travel tips.
          Discover the beauty of one of Russia's most remarkable regions.
        </p>

        <div className="bg-[#e0dfdb] flex px-2 py-1.5 rounded-full text-left mt-10">
          <input
            type="email"
            placeholder="Enter your email"
            className="w-full outline-none bg-transparent text-sm pl-4"
          />
          <button
            type="button"
            className="bg-green-600 hover:bg-green-700 text-white text-sm rounded-full px-5 py-2.5 ml-4 transition-all">
            Subscribe
          </button>
        </div>
      </div>

      <hr className="border-gray-300 my-12" />

      <div className="grid max-sm:grid-cols-1 max-lg:grid-cols-2 lg:grid-cols-4 gap-8">
        <div>
          <h4 className="text-lg font-bold mb-6 text-gray-800">About Altai Krai</h4>
          <p className="text-gray-600 mb-2 text-sm">
            Altai Krai is a land of breathtaking landscapes, rich culture, and historical heritage.
            Explore the wonders of its mountains, rivers, and unique traditions.
          </p>
        </div>

        <div>
          <h4 className="text-lg font-bold mb-6 text-gray-800">Explore</h4>
          <ul className="space-y-4">
            <li>
              <a
                href="javascript:void(0)"
                className="text-gray-600 hover:text-gray-800 text-[15px]">
                Scenic Spots
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="text-gray-600 hover:text-gray-800 text-[15px]">
                Cultural Events
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="text-gray-600 hover:text-gray-800 text-[15px]">
                Outdoor Adventures
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="text-gray-600 hover:text-gray-800 text-[15px]">
                Local Cuisine
              </a>
            </li>
          </ul>
        </div>

        <div>
          <h4 className="text-lg font-bold mb-6 text-gray-800">Plan Your Visit</h4>
          <ul className="space-y-4">
            <li>
              <a
                href="javascript:void(0)"
                className="text-gray-600 hover:text-gray-800 text-[15px]">
                Travel Guide
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="text-gray-600 hover:text-gray-800 text-[15px]">
                Accommodations
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="text-gray-600 hover:text-gray-800 text-[15px]">
                Transportation
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="text-gray-600 hover:text-gray-800 text-[15px]">
                FAQs
              </a>
            </li>
          </ul>
        </div>

        <div>
          <h4 className="text-lg font-bold mb-6 text-gray-800">Contact Us</h4>
          <ul className="space-y-4">
            <li>
              <a
                href="javascript:void(0)"
                className="text-gray-600 hover:text-gray-800 text-[15px]">
                Email Us
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="text-gray-600 hover:text-gray-800 text-[15px]">
                Visit Us
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="text-gray-600 hover:text-gray-800 text-[15px]">
                Social Media
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className="text-gray-600 hover:text-gray-800 text-[15px]">
                Feedback
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default AltaiFooter;
